<template>
  <div class="alert-form">
    <BaseInput name="keyword" 
               ref="keywordInput"
               label="Keyword to monitor"   
               placeholder="keyword to monitor" 
               :value="formData.keyword"
              @input="handleInput" />

    <BaseInput name="frequencyHours" 
               label="How often do you want to pull new posts" 
               type="number" 
               :min="1"
               :value="formData.frequencyHours" 
               @input="handleInput">
      <template #before>
        <span class="m-r-8">
          Every
        </span>
      </template>
      <span class="m-l-8">
        {{ parseInt(formData.frequencyHours) === 1 ? 'Hour' : 'Hours' }}
      </span>
    </BaseInput>

    <BaseInput name="frequencyTimeHour" 
               label="At what time should it be done? EST time." 
               type="number"
               :min="1" :max="12" 
               :value="formData.frequencyTimeHour" 
               @input="handleInput">
      <template #before>
        <span class="m-r-8">
          At
        </span>
      </template>
      <div class="inline">
        <button @click="handleInput({ name: 'frequencyTimePeriod', value: 'AM' })">
          <span :class="{ active: formData.frequencyTimePeriod === 'AM' }">
            AM
          </span>
        </button>
        /
        <button @click="handleInput({ name: 'frequencyTimePeriod', value: 'PM' })">
          <span :class="{ active: formData.frequencyTimePeriod === 'PM' }">
            PM
          </span>
        </button>
      </div>
    </BaseInput>

    <BaseInput name="postsLimit" 
               label="How many posts do you want to pull each time?" 
               placeholder="posts to pull"
               type="number" 
               :min="1" 
               :value="formData.postsLimit" 
               @input="handleInput" />

    <BaseCheckbox name="postsSkipExisting" 
                  label="Do not pick up posts already pulled in the past"
                  :value="formData.postsSkipExisting" 
                  @click="handleInput" />

    <div class="flex">
      <BaseInput name="filterComments" 
               class="m-r-16"
               label="Min, comments" 
               type="number" 
               :min="0"
               :value="formData.filterComments" 
               @input="handleInput" />

      <BaseInput name="filterUpvotes" 
                label="Min, upvotes" 
                type="number" 
                :min="0"
                :value="formData.filterUpvotes" 
                @input="handleInput" />
    </div>

    <BaseInput name="filterPrompt" 
               placeholder="prompt" 
               type="textarea" 
               :value="formData.filterPrompt"
               @input="handleInput">
      <template #label="{ id }">
        <label :for="id">
          Prompt to filter the post
          <span>
            Only pull the post if
          </span>
        </label>
      </template>
      <span>
        If not ignore the post
      </span>
    </BaseInput>

    <div class="actions">
      <slot name="actions" 
            :payload="formData" 
            :primary-disabled="primaryActionDisabled" 
            :secondary-disabled="secondaryActionDisabled"/>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref, watch } from "vue"
  import { useStore } from "vuex"

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    testForChanges: Boolean
  })

  const emit = defineEmits(["input"])
  const { state } = useStore()

  const keywordInput = ref(null)
  const formData = ref({...props.data})
  const reqStatus = computed(() => state.reqStatus)
  const hasRequiredFields = computed(() => !!formData.value.keyword)
  const hasChanges = computed(() => {
    if (!props.testForChanges) return true
    return Object.entries(props.data).some(([key, value]) => formData.value[key] !== value)
  })
  const primaryActionDisabled = computed(() => 
    reqStatus.value === 'sending' || !hasRequiredFields.value || !hasChanges.value
  )
  const secondaryActionDisabled = computed(() => reqStatus.value === 'sending')

  function handleInput({ name, value }) {
    formData.value = {
      ...formData.value,
      [name]: value
    }
    emit('input', { name, value })
  }

  onMounted(() => {
    keywordInput.value?.focus()
  })

  watch(() => props.data, (newData) => {
    formData.value = {...newData}
  }, { deep: true })

  defineExpose({
    keywordInput
  })
</script>

<style lang="scss">
  @import "../styles/vars";

  .alert-form {
    @media only screen and (min-width: 0) {
      max-width: 424px;
      .base-input {
        &.filterComments input, &.filterUpvotes input {
          max-width: 124px;
        }
        &.frequencyTimeHour,
        &.frequencyHours {
          input {
            max-width: 112px;
          }
          button {
            background: none;
            width: fit-content;
            span.active {
              font-weight: 700;
            }
          }
          span {
            display: inline;
          }
        }
        label {
          margin-top: 5px;
          font-weight: 700;
        }
        input {
          margin-bottom: 20px;
        }
        textarea {
          margin-bottom: 4px;
          resize: vertical;
        }
      }
      .base-checkbox {
        flex-direction: column-reverse;
        align-items: baseline;
        margin-top: 0;
        margin-bottom: 19px;
        label {
          margin: 5px 0 19px 0;
          font-weight: 700;
        }
        span {
          display: flex;
          & > span {
            width: fit-content;
          }
        }
      }
      span {
        display: block;
        color: $tech-gray;
        font-weight: 400;
      }
      .actions {
        width: 424px;
        display: flex;
        justify-content: space-between;
        button {
          width: 200px;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
</style>