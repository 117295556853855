<template>
  <div class="instant-tracker-view">
    <h1 class="bold">
      Social Intent Tracker <br>
      <span>Find out who is mentioning keywords such as podcast or webinar on social media </span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="Enter the e-mail address you want to receive the results at"
               placeholder="email address to send csv file"
               :value="emailToSendCSV"
               :required="invalidEmail"
               @input="handleEmail" />

    <p class="form-title">
      Enter a keyword and set up your filters (optional)
    </p>

    <div class="search-section flex align-center">
      <BaseInput name="keyword"
                 placeholder="podcast, webinar, live streaming"
                 :value="form.keyword"
                 @input="handleInput"/>
      <button class="purple-btn"
              :disabled="submitDisabled"
              cy="submit"
              @click="handleSubmit">Submit
      </button>
    </div>

    <BaseCheckbox name="is_linkedin"
                  :value="form.is_linkedin"
                  @click="handleInput"
                  label="Linkedin" />

    <section class="filters-section m-t-48"
             cy="filters"
             :class="{'active-filters': isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <BaseInput name="prompt"
                 type="textarea"
                 placeholder="AI prompt"
                 :value="filters.prompt"
                 @input="handleInputFilter" />

      <BaseInput name="timeframe"
                 placeholder="0"
                 label="How long back should we go when querying search results? (in hours)"
                 :value="filters.timeframe"
                 @input="handleInputFilter">
        <span>hours</span>
      </BaseInput>

      <BaseInput name="days"
                 placeholder="0"
                 label="Once we’re on a page with posts, what’s our cutoff for when a post was posted?"
                 :value="filters.days"
                 @input="handleInputFilter">
        <span>days</span>
      </BaseInput>

      <BaseInput name="pages"
                 placeholder="0"
                 label="How many pages should we scrap per search query?"
                 :value="filters.pages"
                 @input="handleInputFilter">
        <span>pages</span>
      </BaseInput>

      <BaseCheckbox name="notRelevantTargets"
                    :value="filters.notRelevantTargets"
                    @click="handleInputFilter"
                    label="Do not show posts in CSV if no relevant target's email" />

      <BaseCheckbox name="notRiversideClient"
                    :value="filters.notRiversideClient"
                    @click="handleInputFilter"
                    label="Do not show companies or indivduals who are current clients (by email or domain)" />

      <BaseCheckbox name="notScrapedPosts"
                    :value="filters.notScrapedPosts"
                    @click="handleInputFilter"
                    label="Do not scrape a post that was previously scraped (based on URL)" />
      <div class="actions flex">
        <button class="purple-btn"
                cy="save-filters"
                @click="handleSaveFilters">Save filters</button>
      </div>
    </section>
  </div>
</template>

<script setup>
  import { computed } from "vue";
  import {useStore} from "vuex";
  import BaseCheckbox from "@/components/BaseCheckbox.vue";
  import { useFilters } from '@/composables/filters'
  import {useValidations} from "@/composables/validations";

  const { state, dispatch, commit } = useStore()
  dispatch('get_intent_tracker_filters')
  const form = computed(() => state.instantTracker.form)
  const filters = computed(() => state.instantTracker.filters)
  const { isFilters, toggleFilters } = useFilters()
  const reqStatus = computed(() => state.reqStatus)
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const submitDisabled = computed(() => reqStatus.value === 'sending' || !form.value.keyword)
  const { invalidEmail } = useValidations()

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function handleInput ({name, value}) {
    commit('SET_IT_FORM_FIELD', {name, value})
  }

  function handleInputFilter ({name, value}) {
    commit('SET_IT_FILTER', {name, value})
  }

  function handleSaveFilters () {
    dispatch('save_intent_tracker_filters')
  }

  function handleSubmit () {
    if (form.value.keyword && invalidEmail.value) {
      return
    }
    dispatch('submit_linkedin')
  }
</script>

<style lang="scss">
  .instant-tracker-view {
    @media only screen and (min-width: 0) {
      .purple-btn {
        margin-left: 32px;
      }
      .search-section {
        align-items: flex-start;
      }
    }
    .filters-section {
      > div textarea {
        height: 140px;
        font-size: 1.5rem;
      }
      .base-input {
        display: flex;
        align-items: center;
        input {
          width: 40px;
          margin: 0 12px;
          padding-left: 10px;
          padding-right: 0;
        }
        label {
          margin-bottom: 0;
        }
      }
      .purple-btn {
        margin-left: 0;
      }
      &.active-filters {
        height: 780px;
      }
    }
  }
</style>