<template>
  <div class="reddit-monitoring">
    <h1 class="bold">
      Monitor Reddit <br>
      <span>Alerts will be sent to Slack</span>
    </h1>

    <p v-if="isFetching">
      Is fetching data ...
    </p>

    <template v-else>
      <h2 class="bold m-b-24">
        {{ alertName }}
      </h2>

      <AlertForm 
        ref="mainForm"
        :data="form"
        @input="handleInput"
      >
      <template #actions="{primaryDisabled}">
        <button class="purple-btn" 
                :disabled="primaryDisabled" 
                @click="handleSave">
            Save alert
        </button>
      </template>
    </AlertForm>

      <div v-if="alertsData.length">
        <h3 class="bold m-b-24">
          Existing alerts
        </h3>
        
        <ul>
          <li
            v-for="(alert, index) in alertsData"
            :key="index">
            <button
              class="accordion-btn flex align-center justify-between"
              @click="toggleAlert(index)">
              <span>Alert {{ index + 1 }}</span>
              <img src="../assets/arrow.svg" 
                   alt="filter arrow" 
                   :class="['arrow', { active: activeAlertIndex === index }]"/>
            </button>
            <AlertForm 
                class="m-b-16" v-if="activeAlertIndex === index"
                :data="normalizeAlertData(alert)"
                :test-for-changes="true"
              >
              <template #actions="{payload, primaryDisabled, secondaryDisabled}">
                <button class="purple-btn" 
                        :disabled="primaryDisabled" 
                        @click="handleUpdate({payload, id: alert._id})">
                    Save changes
                </button>
                <button class="purple-btn" 
                        :disabled="secondaryDisabled" 
                        @click="handleDelete(alert._id)">
                    Delete alert
                </button>
              </template>
            </AlertForm>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script setup>
  import AlertForm from "@/components/AlertForm.vue";
  import { computed, ref } from "vue";
  import { useStore } from "vuex";

  const { state, dispatch, commit } = useStore()
  dispatch('get_reddit_alerts')

  const mainForm = ref(null)
  const form = computed(() => state.reddit.form)
  const isFetching = computed(() => state.reqStatus === "fetching");
  const alertsData = computed(() => state.reddit.data )
  const alertName = computed(() => `Alert ${alertsData.value?.length + 1}`)
  const activeAlertIndex = ref(null)

  const toggleAlert = (index) => {
    activeAlertIndex.value = activeAlertIndex.value === index ? null : index
  }

  function normalizeAlertData(data) {
    return {
      keyword: data.keyword,
      frequencyHours: data.frequency.hours,
      frequencyTimeHour: data.frequency.time.hour,
      frequencyTimePeriod: data.frequency.time.period,
      postsLimit: data.posts.limit,
      postsSkipExisting: data.posts.skipExisting,
      filterPrompt: data.filter.prompt,
      filterComments: data.filter.comments,
      filterUpvotes: data.filter.upvotes,
    }
  }

  function normalizeAlertPayload(payload) {
    return {
      keyword: payload.keyword,
      frequency: {
          hours: payload.frequencyHours,
          time: {
              hour: payload.frequencyTimeHour,
              period: payload.frequencyTimePeriod
          }
      },
      posts: {
          limit: payload.postsLimit,
          skipExisting: payload.postsSkipExisting
      },
      filter: {
          prompt: payload.filterPrompt,
          upvotes: payload.filterUpvotes,
          comments: payload.filterComments
      }
    }
  }

  function handleInput ({name, value}) {
    commit('SET_REDDIT_STATE', {key: "form", value: {
      ...state.reddit.form,
      [name]: value
    }})
  }

  async function handleSave() {
   await dispatch('save_reddit_alert', normalizeAlertPayload(state.reddit.form))
   mainForm.value.keywordInput?.focus()
  }

  function handleUpdate({payload, id}) {
    dispatch('update_reddit_alert', {payload: normalizeAlertPayload(payload), id})
  }

  async function handleDelete(id) {
   await dispatch('delete_reddit_alert', id)
    activeAlertIndex.value = null
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .reddit-monitoring {
    @media only screen and (min-width: 0) {
      max-width: 1076px;
      margin: 0 auto 100px;
      h3 {
        margin-top: 32px;
        color: #5D5D5D;
      }
      ul {
        max-width: 424px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .accordion-btn {
          width: 100%;
          background-color: white;
          justify-content: space-between;
          padding: 8px 8px 8px 2px;
          border-radius: 4px;
          &:hover {
            background-color: rgb(248, 248, 248);
          }
          .arrow {
            transform: rotate(180deg);
            &.active {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
</style>