import axios from "axios"
import api from "../api"

export const state = {
  data: [],
  form: {
    keyword: "",
    frequencyHours: 1,
    frequencyTimeHour: 1,
    frequencyTimePeriod: "AM",
    postsLimit: 1,
    postsSkipExisting: false,
    filterPrompt: "",
    filterUpvotes: 0,
    filterComments: 0
  }
}

export const mutations = {
  SET_REDDIT_STATE (state, {key, value}) {
    state[key] = value
  },
  SAVE_REDDIT_ALERT (state, value) {
    state.data.push(value)
  },
  UPDATE_REDDIT_ALERT (state, value) {
    const index = state.data.findIndex(item => item._id === value._id)
    state.data[index] = value
  },
  DELETE_REDDIT_ALERT (state, id) {
    const index = state.data.findIndex(item => item._id === id)
    state.data.splice(index, 1)
  },
  RESET_REDDIT_FORM (state) {
    state.form = {
      keyword: "",
      frequencyHours: 1,
      frequencyTimeHour: 1,
      frequencyTimePeriod: "AM",
      postsLimit: 1,
      postsSkipExisting: false,
      filterPrompt: "",
      filterUpvotes: 0,
      filterComments: 0
    }
  },
}

export const actions = {
  async get_reddit_alerts ({ commit }) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const { data } = await axios.get(api.reddit.alerts)
      commit('SET_REDDIT_STATE', { key: 'data', value: data })
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      console.log(err)
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_reddit_alert ({ commit }, payload) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      const { data } = await axios.post(api.reddit.alerts, payload)
      commit('SAVE_REDDIT_ALERT', data)
      commit('RESET_REDDIT_FORM')
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      console.log(err)
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async update_reddit_alert ({ commit }, { payload, id }) {
    try {
     commit('SET_REQ_STATUS', 'sending')
     const { data } = await axios.put(`${api.reddit.alerts}/${id}`, payload)
     commit('UPDATE_REDDIT_ALERT', {...data, _id: id})
     commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      console.log(err)
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async delete_reddit_alert ({ commit }, id) {
    try {
     commit('SET_REQ_STATUS', 'sending')
     await axios.delete(`${api.reddit.alerts}/${id}`)
     commit('DELETE_REDDIT_ALERT', id)
     commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      console.log(err)
      commit('SET_REQ_STATUS', 'failed')
    }
  }
}